import React, { Component } from "react";
import { Table } from "react-bootstrap";
import { useTable } from "react-table";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { NumberFormat } from "../../helpers/NumberFormat";
import { Fetch } from "../../helpers/Fetch";

dayjs.extend(utc);
dayjs.extend(timezone);

class BadReadingsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      all_site_list: [],
      user_site_list: [],
      site_id: "",
      show_general_alert: false,
      bad_data_reads: [],
      show_reader_table: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { device_id, detect_table_pan } = this.props;

    if (prevProps.detect_table_pan !== detect_table_pan || prevProps.device_id !== device_id) {
      this.get_reader_table_data();
    }
  }

  get_reader_table_data = () => {
    const { device_id, site_tz, endDate, initial_date } =
      this.props;

    Fetch("bad_data.php", {
      action: "get_table_data",
      device_id: device_id,
      report_time_zone: site_tz,
      start_date: initial_date.utc().format("MM-DD-YYYY HH:mm"),
      end_date: endDate.utc().format("MM-DD-YYYY HH:mm"),
    })
      .then((data) => {
        this.setState({
          bad_data_reads: data,
          show_reader_table: true,
        });
      })
      .catch((err) => console.log(err));
  };

  handleGeneralAlertClose = () => {
    this.setState({
      show_general_alert: false,
    });
  };

  handleCheckBoxClick = (e, param_id, row) => {
    Fetch("bad_data.php", {
      action: "mark_bad_reading",
      device_id: this.props.device_id,
      gmt_timestamp:  row.original.gmt_timestamp,
      param_id: row.original.param_id,
      log_value_status: e.target.checked ? 1 : 0,
    }).then((data) => {
      this.get_reader_table_data();
    });
  };

  render() {

    const columns = [
      {
        Header: "UTC",
        accessor: "gmt_timestamp",
        key: "gmt_timestamp",
        Cell: (props) => (
          <div>{dayjs(props.cell.value).format("YYYY-MM-DD HH:mm:ss")}</div>
        ),
      },
      {
        Header: "Date/Time",
        accessor: "local_time",
        key: "local_time",
      },
      {
        Header: "kWh",
        accessor: "log_value",
        key: "log_value",
        // Cell: (props) => <div>{NumberFormat().format(props.cell.value)}</div>,
        Cell: (props) => (
          <div>
            {props.cell.value > 0
              ? NumberFormat().format(props.cell.value)
              : props.cell.value}
          </div>
        ),
      },
      {
        Header: "kWh Diff",
        accessor: "kwh_diff",
        key: "kwh_diff",
        Cell: (props) => (
          <div>
            {props.cell.value > 0
              ? NumberFormat(2).format(props.cell.value)
              : props.cell.value}
          </div>
        ),
      },
      {
        Header: "Bad",
        accessor: "log_value_status",
        key: "log_value_status",
        Cell: ({ row }) => (
          <span>
            <input
              type="checkbox"
              defaultChecked={row.original.log_value_status}
              onClick={(e) => this.handleCheckBoxClick(e, 10, row)}
            />
          </span>
        ),
      },
      {
        Header: "kW",
        accessor: "kw_demand",
        key: "kw_demand",
        // Cell: (props) => <div>{NumberFormat().format(props.cell.value)}</div>,
        Cell: (props) => (
          <div>
            {props.cell.value > 0
              ? NumberFormat(1).format(props.cell.value)
              : props.cell.value}
          </div>
        ),
      },
    ];

    function TableRender({ columns, data }) {
      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
      } = useTable({
        columns,
        data,
      });

      rows.sort((a, b) =>
        a.values.gmt_timestamp.localeCompare(b.values.gmt_timestamp)
      );

      return (
        <Table //Bootstrap table settings
          striped
          bordered
          hover
          responsive
          size="sm"
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr key={"header"} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th key={column.key} {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <React.Fragment key={i}>
                  <tr key={row.values.site_id}
                      className={row.values.log_value === '0' ? 'bg-warning' : row.values.kwh_diff === '0' ? 'bg-secondary' : ''}
                      {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </Table>
      );
    }

    const { bad_data_reads, show_reader_table } = this.state;

    return (
      <div>
        {show_reader_table && (
          <div className="mt-5">
            {bad_data_reads && bad_data_reads.length > 0 && (
              <TableRender columns={columns} data={bad_data_reads} />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default BadReadingsTable;
