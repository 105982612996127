import React, { Component } from "react";
import Navigation from "../Navbar";
import AppFooter from "../AppFooter";

import { Fetch } from "../../helpers/Fetch";
import { NumberFormat } from "../../helpers/NumberFormat";
import AlertModal from "../../helpers/AlertModal";
import Select from "react-select";
import "./Invoices.css";
import InvoiceTable from "./InvoiceTable";
import { Alert, Button, Modal, Tab, Form, Tabs } from "react-bootstrap";
import LatestInvoiceTable from "./LatestInvoiceTable";
import SimpleReactValidator from "simple-react-validator";
import { globalDateFormat } from "../../helpers/GlobalDateFormat";
import { CurrencyFormat } from "../../helpers/CurrencyFormat";
import Dropzone from "react-dropzone";
import { s3_upload } from "../../helpers/s3_upload";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import ReportQueue from "./ReportQueue";
import SiteSelect from "../Global/Site_select2";
import PeriodSelect from "../Global/Period_select";
import SiteNotesTextArea from "../Global/SiteNotesTextArea";

dayjs.extend(utc);
dayjs.extend(timezone);

class Invoices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      adjacent_dates_alert: false,
      dated_invoice_alert: false,
      invoice_table_data: [],
      invoice_group_data: [],
      tarrif_table_data: [],
      reporting_period_disabled: true,
      reporting_period_selected: false,
      site_id: sessionStorage.getItem("prev_site_id"),
      site_notes:sessionStorage.getItem("prev_site_notes"),
      show_period_modal: false,
      date_effective: "",
      date_end: "",
      rate_id: sessionStorage.getItem("prev_rate_id"),
      show_utility_modal: false,
      select_utility: [],
      prior_invoices: [],
      new_invoice_from: dayjs().startOf("month"),
      new_invoice_to: dayjs().add(1, "month").startOf("month"),
      new_invoice_date: "",
      new_consumption: 0,
      new_demand: 0,
      new_charges: 0,
      new_discount: 0,
      new_notes: "",
      param_units: "",
      new_utility_invoice_open: false,
      rate_effective_id: sessionStorage.getItem('prev_reid_select'),
      show_error_message: false,
      site_tz: sessionStorage.getItem("prev_site_tz"),
      files: [],

      runConfirmModal: false, //Used to show the run confirmation modal
      runSelection: "",
      runConfirmModalBody: "",
      confirm_save: false,
      file_size_alert: false,
      similar_invoice_alert: false,
      period_date_end: "",
      period_date_effective: "",
      show_report_status: false,
      report_queue_data: [],
    };
    this.onDrop = (files) => {
      this.setState({ files: [...this.state.files, ...files] });
    };
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    this.handlePeriodInvoices()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { show_period_modal, files } = this.state;
    if(!this.state.reporting_period_selected &&
        sessionStorage.getItem("prev_reid_select")
    )
      this.handlePeriodInvoices();

    if (show_period_modal !== prevState.show_period_modal) {
      this.handle_site_change();
      this.forceUpdate();
    }

    if (files !== prevState.files) {
      files &&
        files.forEach((file) => {
          if (file.size > 20000000) {
            this.setState({
              file_size_alert: true,
            });
          }
        });
    }
  }

  handleCloseFileSizeAlert = () => {
    this.setState({
      file_size_alert: false,
      files: [],
    });
  };

  handle_site_change = (e) => {
    if (e !== undefined) {
      if(String(e.rate_id) !== String(this.state.rate_id)) {
        this.setState({
          rate_effective_id: '',
          reporting_period_selected:false,
          invoice_group_data:[],
          reporting_period_disabled:true})
      }

      this.setState({
        site_id: e.site_id,
        site_tz: e.site_tz,
        rate_id: e.rate_id,
      });
    }
  };

  handle_period_change = (e) => {
    console.log("handle_period_change e state",e.target.value,  e)
    // const reid_select= e && e.target.value ? e.target.value : this.state.rate_effective_id;
    sessionStorage.setItem("prev_period_label", e.label );
    sessionStorage.setItem("prev_reid_select", e.target.value);

    this.setState({
      rate_effective_id: e.target.value
    });

    this.handlePeriodInvoices(e)
  };

  handlePeriodInvoices = (e) => {
    const { site_id, rate_effective_id } = this.state;
    // const reid_select= e !== undefined ? e.target.value : rate_effective_id
    // if(!reid_select) return

      Fetch("utilityInvoices_api.php", {
        action: "fill_invoices_table",
        site_id: site_id,
        rate_effective_id: rate_effective_id,
      }).then((data) => {
        if (data.status === "ok") {
          // if (e !== undefined) {
            this.setState({
              reporting_period_selected: true,
              invoice_group_data: data.data,
              reporting_period_disabled:false,
              // rate_effective_id: e && e.value ? e.value : rate_effective_id,
              reporting_period_status_keep: data.status_keep === "true",
              // new_invoice_from: dayjs(e.date_effective).format("YYYY-MM-DD"),
              // new_invoice_to: dayjs(e.date_end).format("YYYY-MM-DD"),
            });
          // } else {
          //   this.setState({
          //     reporting_period_disabled:false,
          //     reporting_period_selected: true,
          //     invoice_group_data: data.data,
          //     rate_effective_id: rate_effective_id,
          //     reporting_period_status_keep: data.status_keep === "true",
          //   });
          // }
        } else {
          this.setState({
            show_error_message: true,
          });
        }
      });

      // This fetch is for the modal to get the last five invoices for an account
      Fetch("utilityInvoices_api.php", {
        action: "fill_tariff_params_table",
        rate_effective_id: rate_effective_id,
      }).then((data) => {
        this.setState({
          tarrif_table_data: data.data,
        });
      });

  };

  handleReportQueueRefresh=()=>{
    Fetch("utilityInvoices_api.php", {
      action: "get_report_generation_queue",
    }).then((data) => {
      this.setState({
        report_queue_data: data.data,
        show_report_status: true,
      });
    });
  }

  openReportQueueModal=()=>{
    Fetch("utilityInvoices_api.php", {
      action: "get_report_generation_queue",
    }).then((data) => {
      this.setState({
        report_queue_data: data.data,
        show_report_status: true,
      });
    });
  }

  handleRunReportingPeriod = (e) => {
    let run_selection = e.target.value;
    e.target.value = ""; //reset the select

    switch (run_selection) {
      case "1": //Run report
        this.setState({
          runConfirmModal: true, //Show the modal
          run_selection: 1, //Pass data to run with after confirmation
          //Body of the alert message
          runConfirmModalBody: (
            <>
              <h3 className="text-center">
                This operation is VERY CPU intensive!
              </h3>
              Pressing confirm will cause reports to be generated for the entire
              site. This process can take an hour to complete.
              <br />
              <br />
              Are you sure?
            </>
          ),
        });

        break;
      case "1a":
        Fetch("utilityInvoices_api.php", {
          action: "set_latest_run_keep",
          rate_effective_id: this.state.rate_effective_id,
        }).then((data) => {
          console.log("set_latest_run_keep data", data);
        });

        break;
      case "2": //Generate Email/Invoice list spreadsheet
        this.setState({
          runConfirmModal: true, //Show the modal
          run_selection: 2, //Pass data to run with after confirmation
          //Body of the alert message
          runConfirmModalBody: (
            <>
              This action will regenerate the spreadsheet for customer approval.
              You must set keep/approve for all the report runs.
              <br />
              <br />
              Are you sure?
            </>
          ),
        });

        break;
      case "3": //Test emails to the user
        this.setState({
          runConfirmModal: true, //Show the modal
          run_selection: 3, //Pass data to run with after confirmation
          rate_effective_id: this.state.rate_effective_id,
          //Body of the alert message
          runConfirmModalBody: (
            <>
              This action will send test emails to you for all tenants.
              <br />
              <br />
              Are you sure?
            </>
          ),
        });

        break;
      case "4": //DANGER: Deliver invoices to tenants
        this.setState({
          runConfirmModal: true, //Show the modal
          run_selection: 4, //Pass data to run with after confirmation
          rate_effective_id: this.state.rate_effective_id,
          //Body of the alert message
          runConfirmModalBody: (
            <>
              Pressing confirm will cause reports/invoices to be delivered to
              the customer.
              <br />
              <br />
              Have you approved each report group run for the site?
              <br />
              <br />
              Have you done a report email test and verified attached reports?
              <br />
              <br />
              Are you sure you want to proceed to send emails to tenants/energy
              consumers?
            </>
          ),
        });

        break;
      default:
        alert("Unknown run_selection");
    }
  };

  handleRunConfirmConfirmed = () => {
    // console.log(
    //   "handleRunConfirmConfirmed selection",
    //   this.state.run_selection
    // );

    //Close the modal
    this.setState({ runConfirmModal: false });
    Fetch("utilityInvoices_api.php", {
      action: "run_reporting_period",
      rate_effective_id: this.state.rate_effective_id,
      run_selection: this.state.run_selection,
    }).then((data) => {
      console.log("RunReportingPeriod data", data);
    });
  };

  handleClosePeriodModal = () => {
    this.setState({
      show_period_modal: false,
    });
  };

  handleSavePeriodModal = () => {
    const { rate_id, date_effective, date_end } = this.state;
    Fetch("utilityInvoices_api.php", {
      action: "new_rate_effective_id",
      rate_id: rate_id,
      date_effective: date_effective,
      date_end: date_end,
    }).then((data) => {
      this.setState({
        show_period_modal: false,
      });
    });
  };

  handleShowPeriodModal = () => {
    this.setState({
      show_period_modal: true,
    });
  };

  handleDateChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleCloseUtilityModal = () => {
    this.setState({
      show_utility_modal: false,
      new_consumption: "",
      new_demand: 0,
      new_charges: 0,
      new_discount: 0,
      new_notes: "",
      new_utility_invoice_open: false,
      adjacent_dates_alert: false,
      dated_invoice_alert: false,
      new_invoice_to: "",
      new_invoice_from: "",
      new_invoice_date: "",
      files: [],
    });
  };

  handleUpdateParameters = () => {
    Fetch("utilityInvoices_api.php", {
      action: "update_re_parameters",
      tarrif_table_data: this.state.tarrif_table_data,
    }).then((data) => {
      console.log("data", data);
    });
  };

  handleParamChange = (e) => {
    var tarrif_table = this.state.tarrif_table_data;
    var param_value_id = Number(e.target.name.substring(6));

    // let row = tarrif_table.find((row) => {
    //   if (row.param_value_id === param_value_id) return row;
    // });

    const r = (pvid) =>
      tarrif_table.find(({ param_value_id }) => param_value_id === pvid);

    //row is a pointer to a single row in the tarrif_table array
    //So updating the row updates the array
    //Set the new value to the array
    r(param_value_id).param_value = e.target.value;

    //Set the state
    this.setState({
      tarrif_table_data: tarrif_table,
    });
  };

  handleShowUtilityModal = () => {
    const { rate_id } = this.state;
    this.setState({
      show_utility_modal: true,
    });
    Fetch("utilityInvoices_api.php", {
      action: "pop_selectUtility",
      obj_id: "rates_utility_id",
      rate_id: rate_id,
    }).then((data) => {
      this.setState({
        select_utility: data.data,
      });
    });
  };

  handle_utility_change = (e) => {
    Fetch("utilityInvoices_api.php", {
      action: "fill_prior_invoices",
      rates_utility_id: e.value,
    }).then((data) => {
      this.setState({
        prior_invoices: data.data,
        rates_utility_id: e.value,
        new_utility_invoice_open: true,
      });
    });
  };

  handleUtilityFormChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleUtilityFormSave = () => {
    const {
      rate_effective_id,
      rates_utility_id,
      new_invoice_from,
      new_invoice_to,
      new_invoice_date,
      new_consumption,
      new_demand,
      new_charges,
      new_discount,
      new_notes,
      site_tz,
    } = this.state;

    let date_validation = dayjs(new_invoice_to).isBefore(
      dayjs(new_invoice_from)
    );

    if (!date_validation) {
      if (this.state.files.length > 0) {
        /*
            this.state.files &&   //If there are any files to upload
            s3_upload(
                "energychaser-utility-invoices", //Bucket name
                rates_utility_id + "-" + new_invoice_date.toString() + ".pdf", //filename
                "pdf", //file_mime_type
                this.state.files //pass array of files to be uploaded
            );

            s3_download(  //function will return a file
                "energychaser-utility-invoices", //Bucket name
                rates_utility_id + "-" + new_invoice_date.toString() + ".pdf", //filename
                "pdf", //file_mime_type
            );

            //s3_upload_api.php
            //s3_download_api.php

             */

        //  Filename to be rate_effective_id+rates_utility_id+date_effective+file_number (For uploaded file)
        let filename =
          rate_effective_id + rates_utility_id + new_invoice_date + ".pdf";

        s3_upload(
          "energychaser-utility-invoices",
          "upload_file",
          filename,
          "pdf",
          this.state.files && this.state.files[0],
          "utilityInvoices_api.php"
        );
      }

      Fetch("utilityInvoices_api.php", {
        action: "new_invoice",
        rate_effective_id: rate_effective_id,
        rates_utility_id: rates_utility_id,
        new_invoice_from: dayjs.tz(new_invoice_from, site_tz),
        new_invoice_to: dayjs.tz(new_invoice_to, site_tz),
        new_invoice_date: dayjs.tz(new_invoice_date, site_tz),
        new_consumption: new_consumption,
        new_demand: new_demand,
        new_charges: new_charges,
        new_discount: new_discount,
        new_notes: new_notes,
      }).then(() => {
        this.setState(
          {
            show_utility_modal: false,
            new_consumption: "",
            new_demand: 0,
            new_charges: 0,
            new_discount: 0,
            new_notes: "",
            new_utility_invoice_open: false,
            adjacent_dates_alert: false,
            dated_invoice_alert: false,
            new_invoice_to: "",
            new_invoice_from: "",
            new_invoice_date: "",
            files: [],
          },
          () => this.handlePeriodInvoices()
        );
      });
    } else {
      this.setState({
        show_error_message: true,
      });
    }
  };

  handleGeneralAlertClose = () => {
    this.setState({
      show_error_message: false,
    });
  };

  handleCloseAlertModal = () => {
    this.setState({
      adjacent_dates_alert: false,
      dated_invoice_alert: false,
      similar_invoice_alert: false,
    });
  };

  handleSaveAlertModal = () => {
    this.setState({
      adjacent_dates_alert: false,
      dated_invoice_alert: false,
      confirm_save: true,
    });
  };

  closeReportQueueModal = () => {
    this.setState({
      show_report_status: false,
    });
  };

  handleValidateSave = () => {
    const { new_invoice_from, prior_invoices, new_invoice_date } = this.state;

    if (this.validator.allValid()) {
      prior_invoices.forEach((invoice) => {
        if (
          dayjs(invoice.bill_date).format("YYYY-MM-DD") === new_invoice_date
        ) {
          this.setState({
            similar_invoice_alert: true,
          });
        }
      });

      if (
        //use service to
        prior_invoices.length > 0 &&
        dayjs(new_invoice_from).diff(
          dayjs(prior_invoices[0].service_to),
          "hours"
        ) > 0
      ) {
        this.setState({
          adjacent_dates_alert: true,
        });
      } else if (
        prior_invoices.length > 0 &&
        dayjs(new_invoice_from).isBefore(
          dayjs(prior_invoices[0].service_from),
          "days"
        )
      ) {
        console.log("Back Dated invoice alert");
        this.setState({
          dated_invoice_alert: true,
        });
      } else {
        this.handleUtilityFormSave();
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    const {
      reporting_period_disabled,
      invoice_group_data,
      show_period_modal,
      tarrif_table_data,
      date_end,
      date_effective,
      show_utility_modal,
      select_utility,
      prior_invoices,
      new_invoice_from,
      new_invoice_to,
      new_invoice_date,
      new_consumption,
      new_demand,
      new_charges,
      new_discount,
      new_notes,
      param_units,
      new_utility_invoice_open,
      show_error_message,
      site_id,
      site_tz,
      rate_effective_id,
      rate_id,
      reporting_period_status_keep,
      reporting_period_selected,
      files,
      adjacent_dates_alert,
      dated_invoice_alert,
      file_size_alert,
      similar_invoice_alert,
      show_report_status,
      report_queue_data,
      site_notes
    } = this.state;

    const select_utility_options = select_utility.map((val) => ({
      label: val.o_text,
      value: val.o_value,
      param: val.param_units,
    }));

    const handleSiteNotesChange = (value)=>{
      this.setState({
        site_notes: value
      });
    }

    const handle_file_delete = (index) => {
      files.splice(index, 1);
      this.setState({ files: files });
    };

    const dropped_files = files.map((file, index) => (
      <li
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          listStyle: "none",
        }}
        key={index}
      >
        <i
          className="fa fa-trash me-2"
          style={{ color: "red" }}
          onClick={(e) => handle_file_delete(index)}
          aria-hidden="true"
        />
        {file.name} - {Math.trunc(file.size / 1000000)}Mb
      </li>
    ));

    return (
      <div>
        <Navigation />
        <Modal
          show={show_report_status}
          onHide={this.closeReportQueueModal}
          size="xl"
        >
          <Modal.Header className="justify-content-center">
            <Modal.Title>Report Generation Queue</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {report_queue_data.length > 0 && (
              <ReportQueue
                report_queue_data={report_queue_data}
                site_tz={site_tz}
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleReportQueueRefresh}>
              Refresh
            </Button>
            <Button variant="secondary" onClick={this.closeReportQueueModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={file_size_alert} onHide={this.handleCloseFileSizeAlert}>
          <Modal.Header closeButton>
            <Modal.Title>File Size Warning!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alert variant="danger">
              File Size Limit Exceeded, accepted limit is 20mb
            </Alert>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleCloseFileSizeAlert}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={
            adjacent_dates_alert || dated_invoice_alert || similar_invoice_alert
          }
          onHide={this.handleCloseAlertModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Invoice Dates Warning!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {adjacent_dates_alert && (
              <Alert variant="danger">
                Invoice Dates Warning Kindly Check and confirm the dates
              </Alert>
            )}
            {dated_invoice_alert && (
              <Alert variant="danger">
                Invoice Dates Warning Kindly Check and confirm the dates
              </Alert>
            )}

            {similar_invoice_alert && (
              <Alert variant="danger">
                Invoice Dates Warning Kindly Check and confirm the dates
              </Alert>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleCloseAlertModal}>
              Close
            </Button>
            {!similar_invoice_alert && (
              <Button variant="danger" onClick={this.handleUtilityFormSave}>
                Save Current Changes
              </Button>
            )}
          </Modal.Footer>
        </Modal>
        <AlertModal
          show={this.state.runConfirmModal}
          handleCancel={() => {
            this.setState({ runConfirmModal: false });
            //   Just close modal and DO NOTHING
          }}
          modalHeading="CAUTION"
          modalBody={this.state.runConfirmModalBody}
          confirmBtnText="Confirm"
          confirmBtnClick={this.handleRunConfirmConfirmed}
        />
        <div className="container-fluid main-container-div">
          <div className="row">
            <div className="col-4 offset-1">
              <div className="input-group">
                <label htmlFor="select_site" className="mt-3 me-2">
                  Select Site
                </label>
                <div className="col-10">
                  <SiteSelect handleSiteChange={this.handle_site_change} site_notes={site_notes}/>
                  <SiteNotesTextArea site_id={site_id} handleSiteNotesChange={handleSiteNotesChange}/>
                </div>
              </div>
            </div>
            <div className="col-7">
              <div className="input-group col-12">
                <PeriodSelect
                    handlePeriodChange={this.handle_period_change}
                    selectedPeriod={rate_effective_id}
                    rate_id={rate_id}
                />
                <button
                  disabled={reporting_period_disabled}
                  className="btn btn-primary mx-1 btn-sm col-2"
                  onClick={this.handleShowPeriodModal}
                >
                  New Period
                </button>

                <button
                    className="btn btn-primary mx-1 btn-sm col-2"
                    onClick={this.openReportQueueModal}
                >
                  Report Queue
                </button>

                  <Form.Control
                      as="select"
                      disabled={!reporting_period_selected}
                      onChange={this.handleRunReportingPeriod}
                  >
                    <option value="">Generate...</option>
                    <option value="1">1 - Generate all reports</option>
                    <option value="1a">
                      &nbsp;&nbsp;&nbsp;1A - Set last run as keeper
                    </option>
                    <option value="2" disabled={!reporting_period_status_keep}>
                      2 - Generate keeper spreadsheet
                    </option>
                    <option value="3" disabled={!reporting_period_status_keep}>
                      3 - Test emails
                    </option>
                    <option
                        value="4"
                        disabled={!reporting_period_status_keep}
                        style={{ backgroundColor: "red" }}
                    >
                      4 - CAUTION: Deliver emails
                    </option>
                  </Form.Control>

              </div>
            </div>
            {show_error_message && (
              <Alert
                variant="danger"
                className="text-center mt-2"
                onClose={this.handleGeneralAlertClose}
                dismissible
              >
                Something went wrong if this unexpected contact your
                administrator!
              </Alert>
            )}
          </div>

          <div className="container col-4 tarriff-table ">
            {reporting_period_selected && tarrif_table_data.length > 0 && (
              <table className="table table-striped table-sm col-10">
                <thead>
                  <tr className="form-group row">
                    <th className="col-10">Parameter Name</th>
                    <th className="col-2 float-end text-end">Value</th>
                  </tr>
                </thead>
                <tbody>
                  {tarrif_table_data.map((val, index) => (
                    <tr className="form-group row" key={index}>
                      <td className="col-10">{val.param_name}</td>
                      <td className="col-2">
                        <input
                          className="form-control float-end text-end"
                          type="text"
                          name={"param_" + val.param_value_id}
                          onChange={this.handleParamChange}
                          value={val.param_value ? val.param_value : ""}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            <div className="text-center">
              {tarrif_table_data.length > 0 && reporting_period_selected && (
                <Button
                  className="btn btn-primary me-5"
                  onClick={this.handleShowUtilityModal}
                >
                  New Utility Invoice
                </Button>
              )}
              {tarrif_table_data.length > 0 && reporting_period_selected && (
                <Button
                  className="btn btn-primary"
                  onClick={this.handleUpdateParameters}
                >
                  Update Parameters
                </Button>
              )}
            </div>
          </div>

          {invoice_group_data.length > 0 && (
            <div>
              <Tabs
                defaultActiveKey="grouped"
                id="uncontrolled-invoice-tab"
                className="mb-3 mt-1"
              >
                <Tab eventKey="grouped" title="Grouped">
                  <button
                    className="btn btn-sm btn-primary mb-2"
                    onClick={() => this.handlePeriodInvoices()}
                  >
                    Refresh Table
                  </button>
                  <span className="p-2">
                    {site_tz} site_id:{site_id}
                  </span>
                  <InvoiceTable
                    rate_effective_id={rate_effective_id}
                    invoice_group_data={invoice_group_data}
                    site_tz={site_tz}
                    handlePeriodChange={() => this.handlePeriodInvoices()}

                  />
                </Tab>
                <Tab eventKey="latest_invoice" title="Latest Invoices">
                  <LatestInvoiceTable site_id={site_id} />
                </Tab>
              </Tabs>
            </div>
          )}
        </div>
        {/* Standard application footer for all pages */}
        <AppFooter />

        <Modal show={show_period_modal} onHide={this.handleClosePeriodModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add New Period</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label>From</label>
            <input
              type="date"
              className="form-control"
              name="date_effective"
              value={date_effective}
              onChange={this.handleDateChange}
            />
            <label>To</label>
            <input
              type="date"
              className="form-control"
              name="date_end"
              value={date_end}
              onChange={this.handleDateChange}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClosePeriodModal}>
              Cancel
            </Button>
            <Button variant="primary" onClick={this.handleSavePeriodModal}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={show_utility_modal}
          onHide={this.handleCloseUtilityModal}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Enter a Utility Invoice</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Select
              name="utility_invoice"
              className="form-control"
              options={select_utility_options}
              onChange={this.handle_utility_change}
            />
            {new_utility_invoice_open && (
              <div>
                <form className="col-md-12">
                  {/* form full width */}

                  <div className="row">
                    {/* The elements below to go side-by-side on screens lg and larger because of the col-lg-6*/}

                    <div className="col-12">
                      <div className="row justify-content-center mt-2">
                        <label
                          htmlFor="invoice_from"
                          className="col-md-2 col-form-label"
                        >
                          Invoice From
                        </label>
                        <div className="col-md-4">
                          <input
                            type="date"
                            id="date"
                            className="form-control"
                            value={new_invoice_from}
                            name="new_invoice_from"
                            onChange={this.handleUtilityFormChange}
                            required
                          />
                          {this.validator.message(
                            "new_invoice_from",
                            new_invoice_from,
                            "required"
                          )}
                        </div>

                        <label
                          htmlFor="invoice_to"
                          className="col-md-2 col-form-label"
                        >
                          Invoice to
                        </label>
                        <div className="col-md-4">
                          <input
                            type="date"
                            id="invoice_to"
                            className="form-control"
                            value={new_invoice_to}
                            name="new_invoice_to"
                            onChange={this.handleUtilityFormChange}
                            required
                          />
                          {this.validator.message(
                            "new_invoice_to",
                            new_invoice_to,
                            "required"
                          )}
                        </div>
                      </div>

                      {/* From and to date div */}

                      <div className="row mt-2">
                        <div className="col-8">
                          <div className="row justify-content-center mt-2">
                            <label
                              htmlFor="new_invoice_date"
                              className="col-md-3 col-form-label"
                            >
                              Invoice Date
                            </label>
                            <div className="col-md-4">
                              <input
                                type="date"
                                id="new_invoice_date"
                                className="form-control"
                                name="new_invoice_date"
                                value={new_invoice_date}
                                onChange={this.handleUtilityFormChange}
                                required
                              />
                              {this.validator.message(
                                "new_invoice_date",
                                new_invoice_date,
                                "required"
                              )}
                            </div>
                          </div>
                          <div className="row justify-content-center mt-2">
                            <label
                              htmlFor="new_consumption"
                              className="col-md-3 col-form-label"
                            >
                              Consumption({param_units})
                            </label>
                            <div className="col-md-4">
                              <input
                                type="number"
                                min="0"
                                className="form-control"
                                name="new_consumption"
                                value={new_consumption}
                                id="new_consumption"
                                onChange={this.handleUtilityFormChange}
                                required
                              />
                              {this.validator.message(
                                "new_consumption",
                                new_consumption,
                                "required"
                              )}
                            </div>
                          </div>
                          <div className="row justify-content-center mt-2">
                            <label
                              htmlFor="new_demand"
                              className="col-md-3 col-form-label"
                            >
                              Demand
                            </label>
                            <div className="col-md-4">
                              <input
                                className="form-control"
                                id="new_demand"
                                name="new_demand"
                                min="0"
                                value={new_demand}
                                onChange={this.handleUtilityFormChange}
                                type="number"
                                required
                              />
                              {this.validator.message(
                                "new_demand",
                                new_demand,
                                "required"
                              )}
                            </div>
                          </div>
                          <div className="row justify-content-center mt-2">
                            <label
                              htmlFor="new_charges"
                              className="col-md-3 col-form-label"
                            >
                              New Charges
                            </label>
                            <div className="col-md-4">
                              <input
                                className="form-control"
                                name="new_charges"
                                min="0"
                                value={new_charges}
                                type="number"
                                onChange={this.handleUtilityFormChange}
                                id="new_charges"
                                required
                              />
                              {this.validator.message(
                                "new_charges",
                                new_charges,
                                "required"
                              )}
                            </div>
                          </div>
                          <div className="row justify-content-center mt-2">
                            <label
                              htmlFor="new_discount"
                              className="col-md-3 col-form-label"
                            >
                              Discount
                            </label>
                            <div className="col-md-4">
                              <input
                                name="new_discount"
                                min="0"
                                value={new_discount}
                                type="number"
                                onChange={this.handleUtilityFormChange}
                                className="form-control"
                                id="new_discount"
                              />
                            </div>
                          </div>
                          <div className="row justify-content-center mt-2">
                            <label
                              htmlFor="new_notes"
                              className="col-md-3 col-form-label"
                            >
                              Notes
                            </label>
                            <div className="col-md-4">
                              <input
                                name="new_notes"
                                value={new_notes}
                                onChange={this.handleUtilityFormChange}
                                className="form-control"
                                id="new_notes"
                                type="text"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-4">
                          {/*<div className="row justify-content-center mt-2">*/}

                          {/*<InvoiceDropZone  />*/}
                          {/*<form className="dropzone">*/}
                          {/*</form>*/}

                          <span className="baseStyleDropZone">
                            <Dropzone
                              onDrop={this.onDrop}
                              maxSize={20000000} //Size is in bytes
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div
                                  {...getRootProps({ className: "dropzone" })}
                                >
                                  <input {...getInputProps()} />
                                  <p className="drop-zone-text">
                                    Drag 'n' drop files here or click to select.
                                  </p>
                                </div>
                              )}
                            </Dropzone>
                          </span>
                          {files.length > 0 && (
                            <aside
                              style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}
                            >
                              <h4>Files</h4>
                              <ul>{dropped_files}</ul>
                            </aside>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <table className="table table-striped table-bordered table-sm">
                  <thead>
                    <tr>
                      <th>Invoice Date</th>
                      <th>Service From</th>
                      <th>Service To</th>
                      <th>Charges</th>
                      <th>Consumption</th>
                    </tr>
                  </thead>
                  <tbody>
                    {prior_invoices.map((val, index) => (
                      <tr key={index}>
                        <td>{dayjs(val.bill_date).format("YYYY-MM-DD")}</td>
                        <td>
                          {globalDateFormat(
                            val.service_from,
                            "YYYY-MM-DD",
                            site_tz
                          )}
                        </td>
                        <td>
                          {globalDateFormat(
                            val.service_to,
                            "YYYY-MM-DD",
                            site_tz
                          )}
                        </td>
                        <td>
                          <div className="text-end">
                            {CurrencyFormat().format(val.current_charges)}
                          </div>
                        </td>
                        <td>
                          <div className="text-end">
                            {NumberFormat(0).format(val.consumption) +
                              " " +
                              param_units}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseUtilityModal}>
              Cancel
            </Button>
            <Button variant="primary" onClick={this.handleValidateSave}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default Invoices;
