// import React, { useEffect, useState } from "react";
import "./App.css";
import {Redirect, Route, Router, Switch} from "react-router";
import {history} from "./helpers/history";
import "./styles/login.css";

// Built in components for all users
import {Login} from "./components/Login";
import {PrivateRoute} from "./helpers/PrivateRoute";
import NoAccess from "./components/NoAccess";
import Register from "./components/Register";
import NotFound from "./helpers/NotFoundPage";

//Installed components
import Alarm from "./components/Alarm/Alarm";
import Invoices from "./components/Invoices/Invoices";
import Tenantv3 from "./components/Tenantv3/Tenantv3";
import NonElectric from "./components/NonElectric/NonElectric";
import Report_Runs from "./components/Report_Runs/Report_Runs";
import Devices from "./components/Devices/Devices";
import Reader from "./components/Reader/Reader";
import Admin2 from "./components/Admin2/Admin2";
import Bad_Data from "./components/Bad_Data/Bad_Data";
// import Appfolio from "./components/Appfolio/Appfolio";
import Documentation from "./components/Documentation/Documentation";
import Queries from "./components/Queries/Queries";
import Extra_Line_Items from "./components/Extra_Line_Items/Extra_Line_Items";
import Tenant_Info from "./components/Tenant_Info/Tenant_Info";
import Landlord_Update from "./components/Landlord_Update/Landlord_Update";
import Home from "./components/Home/Home";

//React functional component
import {UserProfile} from "./components/UserProfile/UserProfile";

// //To be possibly removed temporarily or permanently - Non-production pages at this time
// import Testing from "./components/Testing/Testing";
import ReportHistory from "./components/ReportHistory/ReportHistory";

// import Res_Bill from "./components/Res_Bill/Res_Bill";
// import Tenantv2 from "./components/Tenantv2/Tenantv2";
// import AdminDevices from "./components/AdminDevices/AdminDevices";
// import Checkout from "./components/Checkout/Checkout";

//Disable all console.log statements if not localhost
console.log("window.location.hostname", window.location.hostname);
if (window.location.hostname !== "localhost")
    //if not development, disable console.log
    console.log = function () {
    };

/*
FETCH login.php to check of logged on
If not redirect to login page.
 */
/*
    This array must contain an entry for every imported dynamic route/component above.
    CRITICAL: Text/object names must exactly match the code_file_prefix that is returned from the database
      - Case sensitive
      - First letter must be capitalized.
 */
export const installedComponents = {
    Alarm: Alarm,
    // Res_Bill: Res_Bill, //Name is the same as the tab_id
    // Tenantv2: Tenantv2,
    Invoices: Invoices,
    Tenantv3: Tenantv3,
    NonElectric: NonElectric,
    Report_Runs: Report_Runs,
    Devices: Devices,
    Reader: Reader,
    Admin2: Admin2,
    Bad_Data: Bad_Data,
    // Appfolio: Appfolio,
    Documentation: Documentation,
    Queries: Queries,
    UserProfile: UserProfile,
    // Testing: Testing,
    ReportHistory: ReportHistory,
    Extra_Line_Items:Extra_Line_Items,
    Tenant_Info: Tenant_Info,
    Landlord_Update: Landlord_Update,
    Home: Home
};

function App() {
    let login_data = JSON.parse(localStorage.getItem("login_data"));
    //let default_page = history.push("login")
    let default_page =
        login_data && login_data.user_menu.length > 0
            ? login_data
                ? login_data.user_menu[0].tab_id
                    ? login_data.user_menu[0].tab_id
                    : "Home"
                : history.push("login")
            : history.push("/Login");
    /*
          From server:
            tab_text - This is ONLY to be used for the text on the tab of the nav bar.
            code_file_prefix - This is the component name. Need to upper case the first letter.
            tab_id - This is the URL/PrivateRoute path for the page.
           */

    //Create empty array for all routes
    let routes = [];

    //console.log("app.js Push all dynamic routes", login_data);
    //Push all dynamic routes
    login_data &&
    login_data.user_menu.map(
        (val) =>
            val.code_file_prefix && //code_file_prefix does not exist for top level dropdown tabs - therefore no route & stop here
            (typeof installedComponents[val.code_file_prefix] !== "undefined"
                ? //Component exists - All good to set the route
                routes.push(
                    <PrivateRoute
                        path={"/" + val.tab_id}
                        key={val.tab_id}
                        component={installedComponents[val.code_file_prefix]}
                    />
                )
                : //else if component does not exist, go to not found page
                routes.push(
                    <PrivateRoute
                        path={"/" + val.tab_id}
                        key={val.tab_id}
                        component={NotFound}
                    />
                ))
    );

    console.log("Component routes/pages", routes);
    // console.log('app.js Push all default routes')
    //Push all default routes
    routes.push(<Route path="/login" key="login" component={() => <Login/>}/>);
    routes.push(<Route path="/register" key="register" component={Register}/>);
    routes.push(<Route path="/notfound" key="notfound" component={NotFound}/>);
    routes.push(<Route path="/noaccess" key="notfound" component={NoAccess}/>);
    routes.push(<Redirect key="default_page" to={"/" + default_page}/>);

    console.log("MAIN app.js render");

    function Spinner() {
        return (
            <img src="img/spinner.gif" id="app-spinner" alt="spinner"
                 style={{
                     display: 'none',  //Hidden by default
                     position: 'fixed',
                     top: '50%',
                     left: '50%',
                     width: '120px',
                     height: 'auto',
                     zIndex: '999'
                 }}/>
        )
    }

    return (
        <>
            <Spinner/>
            <div
                className="container-fluid"
                style={{position: "relative", zIndex: 500}}
            >
                <Router history={history}>
                    <Switch>{routes}</Switch>
                </Router>
            </div>
        </>
    )
        ;
}

//console.log("app.js complete");
export default App;
