import {svr_name} from "./AppConstants";
import {SpinnerOn} from "./SpinnerOn";
import {SpinnerOff} from "./SpinnerOff";

//For test countdown counter
let fetch_cnt = 4,
    fetch_cnt_start = 5; //every 5th request is bad

/**
 * Does a POST fetch to the server defined in AppConstants
 *
 * @param APIfile - Name of the API file as a string.
 * @param body - Body object of a normal fetch statement
 * @returns {Promise<any>}
 * @constructor
 */
export function Fetch(APIfile, body) {

    // const [loading_api, set_loading_api] = useState(false);
    //For bad comm testing - defaults all good - If testing, every 5th fetch is bad
    let svr_namex = svr_name;

    /**
     * Leave as "all_good" by default to disable error testing and logging
     */
    const comm_test = "all_good"; //Select test to perform - all_good is default and removes all testing


    if ((APIfile.localeCompare("alarm_api.php") !== 0) &&
        (APIfile.localeCompare("user_log_entry_api.php") !== 0)) {  //Exceptions


        //Don't turn on the spinner unless the fetch take more than 500ms
        var timer = setTimeout(() => {
            SpinnerOn()
        }, 250);

        if (fetch_cnt <= 0 && comm_test !== "all_good") {
            switch (comm_test) {
                case "dns_error":
                    //Test for DNS error
                    svr_namex = "https://BAD.energychaser.com/api_react/";
                    break;
                case "incorrect_file": //This will provide a CORS error on localhost development
                    //Test for incorrect file error
                    // Todo: It is highly unlikely we will ever get an incorrect file.
                    // Todo: Instead we may get network errors or programming errors.
                    svr_namex = "https://data4.energychaser.com/BAD_react/";
                    break;
                case "bad_action":
                    //Test for bad action
                    body.action = "BAD"; //Bad action
                    break;
                default:
                //all_good is the default - Do nothing
            }
            fetch_cnt = fetch_cnt_start; //reset counter
        } //default - all good
        else {
            if (comm_test !== "all_good") {
                console.log(
                    "Good fetch - comm testing *" + comm_test + "*: " + fetch_cnt
                );
                fetch_cnt--; //decrement counter
            }
        }
    }

    return (
        fetch(svr_namex + APIfile, {
            method: "POST",
            credentials: "include",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        })
            .then((response) => {

                    clearInterval(timer);
                    SpinnerOff()

                    try {
                        if (response.ok) {
                            return response.json(); //All good
                        } else {
                            console.log("Fetch error: response.ok is false");
                            return response.text();
                        }
                    } catch (err) {
                        console.log("try catch error", err, body);
                        return {status: "error", message: "DNS error"};
                    }
                }
            )
            .catch((error) => {
                    //CORS, DNS, or another communication error
                    console.log("Fetch error: " + APIfile, error, body);
                    return {
                        status: "error",
                        message: "CORS, DNS, or another communication error!",
                    };
                }
            )
    )
}
